import * as React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import { motion } from 'framer-motion';

import Faq from '../components/faq';

const transition_short = { duration: 0.2, ease: [0.6, 0.01, -0.05, 0.9] };
const transition_long = { duration: 0.6, ease: [0.6, 0.01, -0.05, 0.9] };

const Kontakt = ({ data }) => {
	return (
		<>
			<Layout pageTitle={'Kontakt Oss'} background={false}>
				<Helmet
					bodyAttributes={{
						class: 'green-bg',
					}}
				/>
				<style jsx>{`
					[_nghost-bwx-c43] {
						color: #042825;
					}
					.header {
						padding-top: calc(230px * var(--scale));
						padding-bottom: calc(90px * var(--scale));
					}
					.header .container .hero h1 {
						font-weight: 400;
						font-size: 6rem;
						letter-spacing: -3px;
						line-height: 1;
					}
					@media (min-width: 992px) and (max-width: 1199px) {
						.header .container .hero h1 {
							font-size: 8.375rem;
						}
					}
					@media (min-width: 768px) and (max-width: 991px) {
						.header .container .hero h1 {
							font-size: 6rem;
						}
					}
					@media (max-width: 767px) {
						.header .container .hero h1 {
							font-size: 4.625rem;
						}
					}
					.header .container .hero h1 .turn-down {
						display: inline-block;
						margin-bottom: -10.5rem;
						margin-left: 2.5rem;
					}
					@media (max-width: 767px) {
						.header .container .hero h1 .turn-down {
							margin-bottom: -8.25rem;
							margin-left: 4rem;
						}
					}
					.form {
						padding-bottom: calc(160px * var(--scale));
					}
					@media (max-width: 767px) {
						.form {
							padding-bottom: calc(120px * var(--scale));
						}
					}
					.form .container .bb-form__hero {
						padding-bottom: calc(100px * var(--scale));
						max-width: calc(820px * var(--scale));
					}
					@media (min-width: 992px) and (max-width: 1199px) {
						.form .container .bb-form__hero {
							max-width: calc(900px * var(--scale));
						}
					}
					@media (min-width: 768px) and (max-width: 991px) {
						.form .container .bb-form__hero {
							max-width: 100%;
						}
					}
					.form .container .bb-form__hero p {
						font-weight: 400;
						font-size: 1.5rem;
						letter-spacing: -0.5px;
						line-height: 1.33;
					}
					.form .container .bb-form__wrapper {
						text-align: center;
					}
					.form .container .bb-form__wrapper .bb-form {
						display: inline-block;
						text-align: left;
					}
					.form .container .bb-form__wrapper .bb-form .row {
						margin-bottom: calc(35px * var(--scale));
						white-space: nowrap;
					}
					@media (max-width: 767px) {
						.form .container .bb-form__wrapper .bb-form .row {
							display: flex;
							flex-wrap: wrap;
							margin-bottom: 1.25rem;
						}
					}
					.form .container .bb-form__wrapper .bb-form .row span {
						display: inline-block;
						font-weight: 400;
						font-size: 1.25rem;
						letter-spacing: -0.5px;
						line-height: 1;
						padding: calc(16px * var(--scale));
					}
					@media (max-width: 767px) {
						.form .container .bb-form__wrapper .bb-form .row span {
							display: none;
						}
					}
					.form
						.container
						.bb-form__wrapper
						.bb-form
						.row
						span:first-child {
						padding-left: 0;
					}
					.form
						.container
						.bb-form__wrapper
						.bb-form
						.row
						span:last-child {
						padding-right: 0;
					}
					.form .container .bb-form__wrapper .bb-form .row small {
						display: none;
						font-weight: 400;
						font-size: 1.5rem;
						letter-spacing: -0.5px;
						line-height: 1;
						padding-bottom: 0.625rem;
					}
					@media (max-width: 767px) {
						.form .container .bb-form__wrapper .bb-form .row small {
							display: block;
						}
					}
					.form .container .bb-form__wrapper .bb-form .row--textarea {
						display: flex;
						align-items: flex-start;
					}
					.form
						.container
						.bb-form__wrapper
						.bb-form
						.row--textarea
						textarea {
						width: 100%;
					}
					.form .container .bb-form__wrapper .bb-form .row--button {
						text-align: right;
						padding-top: 0.625rem;
					}
					.form
						.container
						.bb-form__wrapper
						.bb-form
						.row--button
						button {
						padding: 0 calc(30px * var(--scale));
						cursor: pointer;
					}
					@media (max-width: 767px) {
						.form
							.container
							.bb-form__wrapper
							.bb-form
							.row--button
							button {
							margin: 0 0 0 auto;
						}
					}
					.form
						.container
						.bb-form__wrapper
						.bb-form
						.row--button
						button
						span {
						display: inline !important;
						padding: 0;
						padding-right: calc(66px * var(--scale));
					}
					.form .container .bb-form__wrapper .footnote {
						display: flex;
						justify-content: flex-end;
					}
					@media (max-width: 767px) {
						.form .container .bb-form__wrapper .footnote {
							justify-content: flex-start;
						}
					}
					.form .container .bb-form__wrapper .footnote p {
						line-height: 1.5;
						letter-spacing: -0.02em;
						max-width: calc(560px * var(--scale));
					}
					@media (max-width: 767px) {
						.form .container .bb-form__wrapper .footnote p {
							max-width: 80%;
						}
					}
					.form .container .bb-form__wrapper .bb-form--success {
						max-width: 40rem;
						min-height: 34.375rem;
						margin: 0 auto;
						padding: 1.25rem;
						font-size: 3rem;
						font-weight: 400;
						letter-spacing: -1px;
						line-height: 1.16;
					}
					.form .container .bb-form__wrapper .bb-form--success img {
						display: block;
						margin: 0 auto 3.125rem;
					}
					.contacts .container {
						position: relative;
						padding-top: calc(120px * var(--scale));
						padding-bottom: calc(120px * var(--scale));
					}
					@media (min-width: 768px) and (max-width: 991px) {
						.contacts .container {
							padding-bottom: calc(60px * var(--scale));
						}
					}
					@media (max-width: 767px) {
						.contacts .container {
							padding-bottom: calc(30px * var(--scale));
						}
					}
					.contacts .container:before {
						content: '';
						display: block;
						position: absolute;
						top: 0;
						left: calc(20px * var(--scale));
						right: calc(20px * var(--scale));
						height: 1px;
						background: rgba(235, 235, 235, 0.5);
					}
					.contacts .container > h2 {
						max-width: calc(820px * var(--scale));
						letter-spacing: -1px;
						line-height: 1.16;
						padding-bottom: calc(120px * var(--scale));
					}
					@media (max-width: 767px) {
						.contacts .container > h2 {
							font-size: 2.5rem;
						}
					}
					.contacts .container > h2 a:after,
					.contacts .container > h2 a:before {
						height: 2px;
					}
					.contacts .container .texts {
						padding-left: calc(33.33333333% + 10px);
					}
					@media (min-width: 576px) {
						.contacts .container .texts {
							display: flex;
							align-items: flex-start;
							flex-wrap: wrap;
							margin-left: -1.25rem;
							margin-right: -1.25rem;
						}
					}
					@media (min-width: 992px) and (max-width: 1199px) {
						.contacts .container .texts {
							padding-left: 15%;
						}
					}
					@media (min-width: 768px) and (max-width: 991px) {
						.contacts .container .texts {
							padding-left: 10%;
						}
					}
					@media (max-width: 767px) {
						.contacts .container .texts {
							padding-left: 0;
						}
					}
					.contacts .container .texts > div {
						margin-bottom: calc(80px * var(--scale));
					}
					@media (min-width: 576px) {
						.contacts .container .texts > div {
							flex: 1 0 0;
							min-width: 50%;
							padding-left: 1.25rem;
							padding-right: 1.25rem;
						}
					}
					.contacts .container .texts > div h3 {
						font-size: 2rem;
						margin-bottom: 0.625rem;
						line-height: 1.25;
						letter-spacing: -1px;
					}
					@media (max-width: 767px) {
						.contacts .container .texts > div h3 {
							font-size: 1.75rem;
						}
					}
					.contacts .container .texts > div address {
						font-style: normal;
					}
					.contacts .container .texts > div p {
						font-weight: 400;
						font-size: 1.25rem;
						line-height: 1.4;
						letter-spacing: -0.02em;
					}
					.contacts .container .texts > div .btn {
						margin-top: 1.25rem;
					}
					.contacts .container .texts > div ul {
						list-style: none;
						margin: 0;
						padding: 0;
					}
					.contacts .container .texts > div ul li {
						font-weight: 400;
						font-size: 1.25rem;
						line-height: 1.4;
						letter-spacing: -0.5px;
					}
					.contacts .container .texts > div ul li a {
						text-decoration: none;
					}
					.faq-section {
						position: relative;
						background: #042825;
						color: #fff;
						padding-bottom: calc(180px * var(--scale));
					}
					@media (max-width: 767px) {
						.faq-section {
							padding-bottom: calc(120px * var(--scale));
						}
					}
					.faq-section:after {
						content: '';
						position: absolute;
						bottom: -1px;
						left: 0;
						right: 0;
						height: 1px;
						background: #042825;
					}
					.faq-section .container {
						position: relative;
					}
					.faq-section .container > h2 {
						letter-spacing: -1px;
						line-height: 1.15;
						max-width: calc(820px * var(--scale));
						padding-top: calc(120px * var(--scale));
						padding-bottom: calc(80px * var(--scale));
					}
					@media (max-width: 767px) {
						.faq-section .container > h2 {
							font-size: 2rem;
						}
					}
					.faq-section .container .wrapper {
						position: relative;
						padding-left: 34%;
					}
					@media (max-width: 1199px) {
						.faq-section .container .wrapper {
							padding-left: 0;
						}
					}
					.faq-section .container .wrapper > img,
					.faq-section .container .wrapper > picture {
						position: absolute;
						left: calc(40px * var(--scale));
						top: 0;
						width: auto;
						max-width: 100%;
					}
					@media (max-width: 1499px) {
						.faq-section .container .wrapper > img,
						.faq-section .container .wrapper > picture {
							max-width: 300px;
							max-height: 300px;
							bottom: calc(70px * var(--scale));
						}
					}
					@media (max-width: 1199px) {
						.faq-section .container .wrapper > img,
						.faq-section .container .wrapper > picture {
							display: none;
						}
					}

					main.app-content {
						background-color: #000;
						color: #fff;

						background-image: url(../assets/green.png);
						background-repeat: no-repeat;
						background-size: contain;
						background-position: 0 0;
					}
					.tdua:after,
					.tdua:before {
						background-color: rgba(235, 235, 235, 0.4);
					}
				`}</style>
				<main className="ng-star-inserted" id="contact-wrapper">
					<motion.div
						initial={{ opacity: 0, y: 50 }}
						animate={{ opacity: 1, y: 0 }}
						exit={{ opacity: 0 }}
						transition={transition_long}
					>
						<div className="header">
							<div className="container">
								<div className="hero">
									<h1>
										Ta kontakt, så ser vi på oppdraget
										sammen.
									</h1>
								</div>
							</div>
						</div>

						<div className="contacts">
							<div className="container">
								<h2>
									<a
										href="tel:+4791390617"
										className="tdua thick"
									>
										Ring
									</a>{' '}
									eller{' '}
									<a
										href="mailto:bjornar@gronn-kompetanse.no"
										className="tdua thick"
									>
										send oss en e-post
									</a>{' '}
									for oppdrag og andre henvendelser!
								</h2>
								<div className="texts">
									<div>
										<h3>
											<a
												href="mailto:bjornar@gronn-kompetanse.no"
												className="tdua thick"
											>
												bjornar@gronn-kompetanse.no
											</a>
										</h3>
										<p>Oppdrag, generelt og presse.</p>
									</div>
									<div>
										<h3>
											<a
												href="tel:+4791390617"
												className="tdua thick"
											>
												+47 913 90 617
											</a>
										</h3>
										<p>Hvis du ønsker å snakke med oss.</p>
										{/* <a
											routerlink="/careers"
											className="btn btn-sm"
											href="/careers"
										>
											Knapp uten vei
										</a> */}
									</div>
									<div>
										<h3>Hovedkontor - Drammen</h3>
										<address>
											<p>
												{' '}
												Tegleverksveien 1 <br /> 3413
												Lierstranda, <br /> Norge{' '}
											</p>
										</address>
									</div>
									<div>
										<h3>Sosiale medier</h3>
										<ul>
											<li>
												LinkedIn:{' '}
												<a
													href="https://www.linkedin.com/"
													target="_blank"
													className="tdua"
												>
													gnk
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<section className="faq-section">
							<Faq header={data.datoCmsHomePage.faqOverskrift} />
						</section>
					</motion.div>
				</main>
			</Layout>
		</>
	);
};

export default Kontakt;

export const Head = () => (
	<>
		<title>Kontakt — Grønn Næringskompetanse</title>

		<meta name="author" content="Bjørnar Bjelland" />
		<meta name="copyright" content="Grønn Næringskompetanse" />
		<meta name="robots" content="follow" />
		<meta name="language" content="Norwegian"></meta>
		<meta name="revisit-after" content="7 days"></meta>
		<meta
			name="description"
			content="Måter du kan komme i kontakt med oss, samt svar på ofte stilte spørsmål."
		/>
		<meta
			name="keywords"
			content="kontakt, kontakt oss, ring, epost, forespørsel"
		/>
	</>
);
export const query = graphql`
	query FaqQuery {
		datoCmsHomePage {
			faqOverskrift
		}
	}
`;
